














































































































































import Vue from 'vue';
import AppReport from '@/components/AppReport.vue';
import AppForm from '@/components/AppForm.vue';
import FieldDate from '@/components/FieldDate.vue';
import AppLoader from '@/components/AppLoader.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListItem from '@/components/AppListItem.vue';
import REPORT_PAYROLL from '@/graphql/queries/ReportPayroll.graphql';
import REPORT_PAYROLL_EXPORT from '@/graphql/queries/ReportPayrollExport.graphql';
import { required } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import formatDate from '@/utils/formatDate';
import formatCurrency from '@/utils/formatCurrency';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import {
  ReportPayrollQuery,
  ReportPayrollExportQuery,
  ReportPayrollQueryVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';

export default Vue.extend({
  name: 'AdminReportsPayroll',
  components: {
    AppReport,
    AppForm,
    FieldDate,
    AppLoader,
    AppPanel,
    AppPanelItem,
    AppListItem,
  },
  data() {
    return {
      form: {
        startDate: '',
        endDate: '',
      },
      reportPayroll: [] as ReportPayrollQuery['reportPayroll'],
    };
  },
  validations() {
    return {
      form: {
        startDate: { required },
        endDate: {
          required,
          afterTime: (value, { startDate }) =>
            !startDate || isAfter(parseISO(value), parseISO(startDate)),
        },
      },
    };
  },
  methods: {
    formatDate,
    formatCurrency,
    validationMessages,
    reset() {
      this.$router.push({
        name: this.$route.name ?? '',
        query: {},
      });
    },
    async runReport() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$router.push({
        name: this.$route.name ?? '',
        query: { ...this.form },
      });
    },
    async exportReport() {
      const { startDate, endDate } = this.$route.query;
      type ExportResult = ApolloQueryResult<ReportPayrollExportQuery>;
      const response: ExportResult = await this.$apollo.query({
        query: REPORT_PAYROLL_EXPORT,
        variables: { startDate, endDate },
        fetchPolicy: 'network-only',
      });
      const { reportPayrollExport } = response.data;
      if (reportPayrollExport && reportPayrollExport.url) {
        window.open(reportPayrollExport.url);
      }
    },
  },
  apollo: {
    reportPayroll: {
      query: REPORT_PAYROLL,
      variables(): ReportPayrollQueryVariables {
        const { startDate: start, endDate: end } = this.$route.query;
        const startDate = Array.isArray(start) ? '' : start;
        const endDate = Array.isArray(end) ? '' : end;
        return { startDate, endDate };
      },
      skip(): boolean {
        const { startDate, endDate } = this.$route.query;
        return !startDate || !endDate;
      },
    },
  },
});
