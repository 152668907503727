









import Vue from 'vue';

export default Vue.extend({
  name: 'AppReport',
  inheritAttrs: false,
  computed: {
    isRun(): boolean {
      const hasValue = (value: unknown) => !!String(value);
      const hasQueries = Object.values(this.$route.query).some(hasValue);
      return hasQueries;
    },
  },
});
